import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorMarcel as author } from 'data/authors';
import img from 'img/blog/lsreactang.png';

const Content = () => {
  return (
    <Post>
      <p>
        Install the tracking code with our new package for React and Angular applications. Say hello
        to{' '}
        <a
          href="https://www.npmjs.com/package/@livesession/sdk"
          target="_blank"
          rel="noopener noreferrer"
        >
          LiveSession SDK
        </a>
        !
      </p>
      <p>
        We’ve prepared a package that will help developers work with API and install code faster and
        more easily on a single page application.
      </p>
      <p>
        <strong>Now you can add a tracking code to your React or Angular app</strong>. We made the
        installation process simple and intuitive.
      </p>
      <p>
        Interested? You can read our{' '}
        <a href="/help/react-and-angular-integration/" target="_blank" rel="noopener noreferrer">
          help article
        </a>
        , or the documentation on the{' '}
        <a
          href="https://developers.livesession.io/javascript-api/platforms/"
          target="_blank"
          rel="noopener noreferrer"
        >
          LiveSession Developers
        </a>{' '}
        page.
      </p>
      <p>
        And there is more: we’ve also released a <strong>Gatsby plugin</strong>! Many of you have
        asked for this plugin, as this platform is growing rapidly.
      </p>
      <p>
        To install LiveSession on your Gatsby website, read the tutorial on{' '}
        <a
          href="https://www.gatsbyjs.org/packages/@livesession/gatsby-plugin-livesession/"
          target="_blank"
          rel="noopener noreferrer"
        >
          the official plugin’s page
        </a>
        , or in our{' '}
        <a href="/help/gatsby-integration/" target="_blank" rel="noopener noreferrer">
          help center
        </a>
        .
      </p>
      <p>
        If you have any ideas for improvement, send us an email to{' '}
        <a href="mailto:hello@livesession.io">hello@livesession.io</a>. Other new integrations are
        coming soon. Stay tuned!
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'LiveSession SDK is live!',
  url: '/blog/livesession-sdk/',
  description: `Learn more about LiveSession SDK and integration with popular frameworks.`,
  author,
  img,
  imgSocial: img,
  date: '2020-06-04',
  category: '',
  group: 'updates',
  timeToRead: 1,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
